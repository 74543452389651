/**
 * Device 设备列表
 * @type {{}}
 */
import Vue from "vue";
import { Input, Icon, Cascader, Select, Option, Message, Table, Page,Modal } from "view-design";
import { mapMutations, mapState } from "vuex";
import {
    getManufacturerService,
    getDeviceListService,
    getDeviceTypeListService,
    deviceListColumns,
    deviceDataColumns
} from "@/service/device-info-service";
Vue.component('Input', Input);
Vue.component('Icon', Icon);
Vue.component('Cascader', Cascader);
Vue.component('Select', Select);
Vue.component('Option', Option);
Vue.component('Table', Table);
Vue.component('Page', Page);
Vue.component('Modal', Modal);
import $event, { EVENT_NAME } from "@/utils/event-emitter";
import { renderSize } from '@/utils/tools'
import vueQr from 'vue-qr'
import html2canvas from 'html2canvas'
import { saveCode } from "@/utils/tools";
const DeviceList = {
    name:'DeviceList',
    components:{ vueQr },
    computed: {
        ...mapState({
            classifyMenuArray: state => state.system.classifyMenuArray, //分类菜单数据
            selectedMenuId: state => state.system.selectedMenuId, //选中的菜单Id
        }),
    },
    data() {
        return {
            renderSize:renderSize,
            deviceDataColumns:deviceDataColumns,
            fileListVisible:false, //modal 可显示
            qrCodeVisible:false, //code modal 可显示
            deviceListLoading:false,
            deviceTypeLoading:false,
            deviceListHeight:0, //表格高度
            fileName:'', //modal name
            QRCode:'',
            QRObj:{},
            logoSrc:require("@/assets/Icon/logo-img.png"),
            deviceListColumns:deviceListColumns, //设备列表显示字段
            manufacturerArray:[], //生产厂家数据
            selectAllCancel:[], //全选时的临时存储
            deviceListArray:{}, //设备列表数据
            deviceTypeList:{}, //设备资源列表数据
            deviceListParams:{ //提交的数据集
                deviceType:[], //设备分类
                manufacturer:'',// 生产厂家
                search:'', //搜索条件
                currentPage: 1,  //当前页
                displayNumber:10, // 显示条数
            },
            deviceTypeParams:{
                id:'',
                type:'',
                currentPage: 1,  //当前页
                displayNumber:10 // 显示条数
            },

        }
    },
    created() {
        this.SET_SELECTED_MENU_ID([-1,'',''])
        /**
         * 订阅分类菜单列表事件
         */
        $event.$on(EVENT_NAME.DEVICE_MENU_LIST, () => {
            this.deviceListParams.deviceType = this.selectedMenuId
            this.deviceListParams.currentPage = 1
            this.onDeviceListAll().then()
        });
        this.deviceListParams.deviceType = this.selectedMenuId
        this.onManufacturerAll().then()
        this.onDeviceListAll().then()
        this.onTableHeight()
    },
    mounted() {
        window.addEventListener('resize', this.onTableHeight)
    },
    methods: {
        ...mapMutations('system',['SET_DEVICE_INFO','SET_SELECTED_MENU_ID','SET_TAB_ID']),
        /**
         * 计算table高度
         */
        onTableHeight(){
            this.deviceListHeight = window.innerHeight - 223
            // this.windowWidth = window.innerWidth
        },
        /**
         * 重置
         */
        onReset() {
            this.deviceListParams.deviceType = []
            this.deviceListParams.manufacturer = ''
            this.deviceListParams.search = ''
        },
        /**
         * 查询设备资源数据
         * @param id
         * @param type
         */
        onDeviceResources(id,type,name) {
            this.fileName = name
            this.deviceTypeParams.id = id
            this.deviceTypeParams.type = type
            this.fileListVisible = true
            this.onDeviceTypeList().then()
        },
        /**
         * 搜索
         */
        onSearch(){
            this.onDeviceListAll().then()
        },
        /**
         * 跳转详情
         * @param row
         */
        onLinkInfo(row,type) {
            this.SET_DEVICE_INFO(row)
            this.SET_TAB_ID(type)
            this.$router.push('/device/info')
        },
        /**
         * 下载图片
         */
        onDowns(){
            html2canvas(this.$refs.deviceRef,{
                backgroundColor: null
            }).then((canvas) => {
                let dataURL = canvas.toDataURL("image/png");
                saveCode(this.QRObj.equipmentName,dataURL)
            });
        },
        /**
         * 查看二维码
         * @param row
         */
        onCode(row){
            // this.QRCode = row.id
            const env = process.env.NODE_ENV;
            this.QRCode = env === 'production'?'https://lingyun.ustep.cn/device/info?id='+row.id : 'https://expert.ustep.cn/device/info?id='+row.id
            this.QRObj = row
            this.qrCodeVisible = true
        },
        /**
         * 发送当前页
         * @param number
         */
        onSearchPage(number){
            this.deviceListParams.currentPage = number
            this.onDeviceListAll().then()
        },
        /**
         * 发送每页显示的条数
         * @param number
         */
        onSearchPageSize(number){
            this.deviceListParams.displayNumber = number
            this.onDeviceListAll().then()
        },
        /**
         * 发送分类当前页
         * @param number
         */
        onTypePage(number){
            this.deviceTypeParams.currentPage = number
            this.onDeviceTypeList().then()
        },
        /**
         * 发送分类每页显示的条数
         * @param number
         */
        onTypePageSize(number){
            this.deviceTypeParams.displayNumber = number
            this.onDeviceTypeList().then()
        },
        /**
         * 下载
         * @param link
         */
        onDownLoad(imgsrc,name){
            let image = new Image();
            // 解决跨域 Canvas 污染问题
            image.setAttribute("crossOrigin", "anonymous");
            image.onload = function() {
                let canvas = document.createElement("canvas");
                canvas.width = image.width;
                canvas.height = image.height;
                let context = canvas.getContext("2d");
                context.drawImage(image, 0, 0, image.width, image.height);
                let url = canvas.toDataURL("image/png"); //得到图片的base64编码数据
                let a = document.createElement("a"); // 生成一个a元素
                let event = new MouseEvent("click"); // 创建一个单击事件
                a.download = name || "photo"; // 设置图片名称
                a.href = url; // 将生成的URL设置为a.href属性
                a.dispatchEvent(event); // 触发a的单击事件
            };
            image.src = imgsrc;
        },
        /**
         * 获取所有生产厂家
         */
        async onManufacturerAll() {
            try {
                const { code,data } = await getManufacturerService()
                if(code === 'SUCCESS'){
                    this.manufacturerArray = data
                }else{
                    Message.error('请求异常，请联系管理员！')
                }

            }catch (error){
                console.log(error)
            }
        },
        /**
         * 拉取分类列表
         */
        async onDeviceListAll() {
            try {
                this.deviceListLoading = true
                this.deviceListArray = {}
                let params = {
                    deviceType:this.deviceListParams.deviceType.length === 0?'':this.deviceListParams.deviceType[2],
                    manufacturer:this.deviceListParams.manufacturer || '',
                    search:this.deviceListParams.search,
                    displayNumber:this.deviceListParams.displayNumber,
                    currentPage:this.deviceListParams.currentPage
                }
                const { code,data } = await getDeviceListService(params)
                if(code === 'SUCCESS'){
                    this.deviceListArray = data
                }else{
                    Message.error('请求异常，请联系管理员！')
                }
                this.deviceListLoading = false
            }catch (error){
                this.deviceListLoading = false
                console.log(error)
            }
        },
        /**
         * 查询设备资源列表
         */
        async onDeviceTypeList() {
            try {
                this.deviceTypeLoading = true
                this.deviceTypeList = {}
                let params = {
                    id:this.deviceTypeParams.id,
                    type:this.deviceTypeParams.type,
                    size:this.deviceTypeParams.displayNumber,
                    page:this.deviceTypeParams.currentPage,
                }
                const { code,data } = await getDeviceTypeListService(params)
                if(code === 'SUCCESS'){
                    this.deviceTypeList = data
                }else{
                    Message.error('请求异常，请联系管理员！')
                }
                this.deviceTypeLoading = false
            }catch (error){
                this.deviceTypeLoading = false
                console.log(error)
            }
        },
    },
    destroyed () {
        $event.$off([EVENT_NAME.DEVICE_MENU_LIST,])
    }
}
export default DeviceList
